
const baseURL = process.env.VUE_APP_BASE_API

import {isShequ} from '@/api/shequ/shequ.js'
export default {
	httpUrl: baseURL,
	adminId: ()=> {
		return window.localStorage.getItem('userid')
	},		//当前登录用户的id
	username: ()=>{
		return window.localStorage.getItem('username')
	},	//当前登录用户的昵称
	level: ()=>{
		return window.localStorage.getItem('level')
	},	//当前登录用户的昵称
	levelname: ()=>{
		return window.localStorage.getItem('levelname')
	},	//当前登录用户的昵称
	arealevel: ()=>{
		return window.localStorage.getItem('arealevel')
	},
	usertype: ()=>{
		return window.localStorage.getItem('usertype')
	},
	phone: ()=>{
		return window.localStorage.getItem('phone')
	},
	token: ()=>{
		return window.localStorage.getItem('token')
	},
	shuquId: ()=>{
		return isShequ({userid:window.localStorage.getItem('userid')}).then(res=>{
			let idString =''
			if(res.result==200){
				res.detail.map((item,index)=>{
					if(index==res.detail.length-1){
						idString=idString+item.levelcode
					}else{
						idString=idString+item.levelcode+','
					}
				})
				return idString
			}else{
				this.$message.error(res.description)
			}
		})
	},//可见社区id字符串
	system_name: '冯墙社区巡防管理系统'
}

