import Vue from 'vue';
import Router from 'vue-router';
import {
	Message
} from 'element-ui'
import global_ from '../utils/config'
import layout from '@/view/IndexContent'
import { getToken } from '@/utils/auth'

Vue.use(Router);

// 解决报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};



const routes = [{
		path: '',
		redirect: '/index'
	},
	{
		path: '/login',
		component: () => import('@/view/login'),
		name: 'login',
		meta: {
			title: '登录', // 页签标题
		}
	},
	{
		path: '/index',
		component: () => import('@/view/Index'),
		name: 'index',
		meta: {
			title: '首页', // 页签标题
		}
	},
	{
		path: '/monitor',
		// component: () => import('@/view/monitor/indexImg'),
		component: () => import('@/view/monitor/index'),
		name: 'monitor',
		meta: {
			title: '大屏', // 页签标题
		}
	},
	{
		path: '/patrol',
		component: layout,
		children: [{
			path: 'tasks',
			component: () => import('@/view/patrolTasks'),
			name: 'tasks',
			meta: {
				title: '巡防任务'
			}
		},{
			path: 'problems',
			component: () => import('@/view/patrolProblem'),
			name: 'problems',
			meta: {
				title: '巡防问题'
			}
		},{
			path: 'modify',
			component: () => import('@/view/patrolModify'),
			name: 'modify',
			meta: {
				title: '周期修改'
			}
		}]
	},
	{
		path: '/ninesite',
		component: layout,
		children: [{
			path: 'sitelist',
			component: () => import('@/view/ninesite/sitelist.vue'),
			name: 'sitelist',
			meta: {
				title: '场所列表'
			}
		},
		{
			path: 'specialreport',
			component: () => import('@/view/ninesite/specialReport.vue'),
			name: 'specialreport',
			meta: {
				title: '专项汇报'
			}
		},{
			path: 'meeting',
			component: () => import('@/view/ninesite/meeting.vue'),
			name: 'meeting',
			meta: {
				title: '专项工作'
			}
		},
		{
			path: 'tasklist',
			component: () => import('@/view/ninesiteTask/index.vue'),
			name: 'tasklist',
			meta: {
				title: '巡防任务'
			}
		},{
			path: 'ninesiteflow',
			component: () => import('@/view/ninesite/nineSiteflow'),
			name: 'person',
			meta: {
				title: '场所操作流水'
			}
		},{
			path: 'problemlist',
			component: () => import('@/view/ninesiteProblem/index.vue'),
			name: 'problemlist',
			meta: {
				title: '巡防问题'
			}
		},{
			path: 'modify',
			component: () => import('@/view/ninesitemodify/index.vue'),
			name: 'modify',
			meta: {
				title: '周期修改'
			}
		}]
	},
	{
		path: '/records',
		component: layout,
		children: [{
			path: '/',
			component: () => import('@/view/patrolRecords/index.vue'),
			name: 'records',
			meta: {
				title: '巡防记录',
			}
		},]
	},

	{
		path: '/grouprental',
		component: layout,
		children: [{
			path: 'task',
			component: () => import('@/view/ninesite/grouprental/task/index.vue'),
			name: 'grouprentaltask',
			meta: {
				title: '巡防任务'
			}
		},{
			path: 'problem',
			component: () => import('@/view/ninesite/grouprental/problem/index.vue'),
			name: 'grouprentalproblem',
			meta: {
				title: '巡防问题'
			}
		},{
			path: 'check',
			component: () => import('@/view/ninesite/grouprental/check/index.vue'),
			name: 'grouprentalcheck',
			meta: {
				title: '任务审核'
			}
		}]
		
	},
	{
		path: '/sixtypatrol',
		component: layout,
		children: [{
			path: 'task',
			component: () => import('@/view/ninesite/sixtyPatrol/task/index.vue'),
			name: 'sixtypatroltask',
			meta: {
				title: '巡防任务'
			}
		},{
			path: 'problem',
			component: () => import('@/view/ninesite/sixtyPatrol/problem/index.vue'),
			name: 'sixtypatrolproblem',
			meta: {
				title: '巡防问题'
			}
		},{
			path: 'cyclemodify',
			component: () => import('@/view/ninesite/sixtyPatrol/cycleModify/index.vue'),
			name: 'sixtypatrolcyclemodify',
			meta: {
				title: '周期修改'
			}
		}]
		
	},
	{
		path: '/managexiaoqu',
		component: layout,
		children: [{
			path: 'xiaoqu',
			component: () => import('@/view/managexiaoqu/xiaoqu'),
			name: 'xiaoqu',
			meta: {
				title: '小区管理'
			}
		},{
			path: 'loudong',
			component: () => import('@/view/managexiaoqu/loudong'),
			name: 'loudong',
			meta: {
				title: '楼栋管理'
			}
		},{
			path: 'fangjian',
			component: () => import('@/view/managexiaoqu/fangjian'),
			name: 'fangjian',
			meta: {
				title: '房间管理'
			}
		}]
	},
	{
		path: '/shaicha',
		component: layout,
		children: [{
			path: 'fangwu',
			component: () => import('@/view/shaicha/fangwu'),
			name: 'fangwu',
			meta: {
				title: '房屋筛查'
			}
		},{
			path: 'fangwugui',
			component: () => import('@/view/shaicha/new_fangwuGui'),
			name: 'fangwugui',
			meta: {
				title: '房屋筛查',
			}
		},{
			path: 'person',
			component: () => import('@/view/shaicha/person'),
			name: 'person',
			meta: {
				title: '人员筛查'
			}
		},{
			path: 'personflow',
			component: () => import('@/view/shaicha/personFlow'),
			name: 'person',
			meta: {
				title: '人员流动流水'
			}
		}]
	},
	{
		path: '/tag',
		component: layout,
		children: [{
			path: 'management',
			component: () => import('@/view/tag/management'),
			name: 'management',
			meta: {
				title: '类型管理'
			}
		},{
			path: 'subtagmanage',
			component: () => import('@/view/tag/subtagmanage'),
			name: 'subtagmanage',
			meta: {
				title: '子标签管理'
			}
		}]
	},
	{
		path: '/wangge',
		component: layout,
		children: [{
			path: 'wg',
			component: () => import('@/view/wangge/wg'),
			name: 'wg',
			meta: {
				title: '网格管理'
			}
		},{
			path: 'wgld',
			component: () => import('@/view/wangge/wgld'),
			name: 'wgld',
			meta: {
				title: '网格楼栋管理'
			}
		},{
			path: 'wgy',
			component: () => import('@/view/wangge/wgy'),
			name: 'wgy',
			meta: {
				title: '人员管理'
			}
		},{
			path: 'wgwgy',
			component: () => import('@/view/wangge/wgwgy'),
			name: 'wgwgy',
			meta: {
				title: '网格员管理'
			}
		},{
			path: 'fuquan',
			component: () => import('@/view/wangge/fuquan'),
			name: 'fuquan',
			meta: {
				title: '赋权管理'
			}
		}]
	},
	{
		path: '/system',
		component: layout,
		children: [{
			path: 'account',
			component: () => import('@/view/system/account'),
			name: 'account',
			meta: {
				title: '用户管理'
			}
		}]
	},
	{
		path: '*', //!* 是一个通配符
		redirect: '/index'
	}
]



const router = new Router({
	mode: 'hash',
	scrollBehavior: () => ({
		y: 0
	}),
	routes
});

//全局路由守卫
router.beforeEach((to, from, next) => {
	if(to.path!='/wenjuanhtml'){
		const title = to.meta && to.meta.title;
		if (title) {
			document.title = global_.system_name + '——' + title;
		}
		let username = window.localStorage.getItem('username');
		let adminId = window.localStorage.getItem('userid');
		if (getToken() === undefined && to.path != '/login') {
			Message({
				message: '请登录系统',
				type: 'warning'
			});
			next({
				path: "/login"
			});
		} else if (getToken() && username && adminId && to.path == '/login') {
			Message({
				message: '您已登录系统',
				type: 'success'
			});
			next({
				path: '/index'
			})
		} else {
			next();
		}
	}else{
		next();
	}

});



export default router