<template>
	<el-container class="contain">
		<el-header class="header">
			<img class="tit" @click="gotoHome" src="../assets/imgs/index_title.png" alt="">
			<span class="collapse" @click="isCollapse = !isCollapse">
				<i class="iconfont icon-zhankai" v-if="isCollapse"></i>
				<i class="iconfont icon-shousuo" v-else></i>
			</span>
			

			<div class="name" @mouseenter="menuFlag = true" @mouseleave="menuFlag = false">
				<div class="username">
					<div class="user"><i class="el-icon-user-solid"></i></div>
					<div>
						<span>{{ username || "松果科技" }}，您好 </span>
						<i class="el-icon-caret-bottom"></i>
					</div>
				</div>

				<div class="menus" :class="menuFlag ? 'show' : ''">
					<ul>
						<li @click="editPassword" class="password"><i class="el-icon-lock"></i>修改密码</li>
					</ul>
				</div>
			</div>

			<div class="quit" @click="quit()">
				<i class="iconfont icon-tuichu"></i>
			</div>
		</el-header>

		<el-container class="main">
			<el-aside class="side" :class="isCollapse==false ? 'addwidth':'removewidth'">
				<el-scrollbar style="height: 100%">
					<!-- <el-menu
            :default-active="$route.path"
            :router="isRouter"
            background-color="#002664"
            text-color="#fff"
            active-text-color="#fff"
            :unique-opened="true"
            :collapse="isCollapse"
          >
            <menutree :data="menu_data"></menutree>
          </el-menu> -->
					<el-menu :default-active="$route.path" :router="isRouter" background-color="#081944"
						text-color="#fff" active-text-color="#fff" :unique-opened="true" :collapse="isCollapse">
						<!--动态生成sideItem-->
						<template v-for="(item, parentIndex) in menu_data">
							<SideNav :item="item" :index="parentIndex" :key="parentIndex"></SideNav>
						</template>
					</el-menu>

				</el-scrollbar>

			</el-aside>

			<el-main class="mainbox">
				<router-tab class="default" />
			</el-main>
		</el-container>

		<!-- 修改密码-->
		<el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="400px" :before-close="cancelbtn"
			:close-on-click-modal="false">
			<el-form :model="form" class="passworddialog">
				<el-form-item label="新密码" :label-width="formLabelWidth">
					<el-input v-model="form.password" type="password" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="确认新密码" :label-width="formLabelWidth">
					<el-input v-model="form.sure_password" type="password" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancelbtn">取 消</el-button>
				<el-button type="primary" @click="surebtn_changePw">确认修改</el-button>
			</div>
		</el-dialog>
	</el-container>
</template>

<script>
	import {
		removeToken
	} from '@/utils/auth'
	import SideNav from "@/components/common/SideNav";
	import {
		updatePwd,
		curUserMenu
	} from "@/api/login"
	export default {
		name: "IndexContent",
		components: {
			SideNav
		},
		data() {
			return {
				isRouter: true,
				username: this.GLOBAL.username(),
				menu_data: [],
				menuFlag: false,
				form: {
					password: "",
					sure_password: "",
				},
				formLabelWidth: "120px",
				dialogFormVisible: false,
				isCollapse: false,
				menu_datas: {
					personAndHouse: [{
						menuname: "筛查",
						menupic: "el-icon-setting",
						menuurl: null,
						childrenmenu: [{
							menuname: "人员筛查",
							menupic: null,
							menuurl: "/shaicha/person",
						},{
							menuname: "房屋筛查",
							menupic: null,
							menuurl: "/shaicha/fangwu",
						}],
					}],
					ninePlace: [{
						menuname: "场所巡查",
						menupic: "el-icon-s-promotion",
						menuurl: null,
						childrenmenu:[{
							menuname: '场所列表',
							menupic: null,
							menuurl: '/ninesite/sitelist'
						},{
							menuname: '巡防任务',
							menupic: null,
							menuurl: '/ninesite/tasklist'
						},{
							menuname: '巡防问题',
							menupic: null,
							menuurl: '/ninesite/problemlist'
						},{
							menuname: '专项工作',
							menupic: null,
							menuurl: '/ninesite/meeting'
						}]
					},{
						menuname: "群租房巡防",
						menupic: "el-icon-s-promotion",
						menuurl: null,
						childrenmenu:[{
							menuname: '巡防任务',
							menupic: null,
							menuurl: '/grouprental/task'
						},{
							menuname: '巡防问题',
							menupic: null,
							menuurl: '/grouprental/problem'
						}
						// ,{
						// 	menuname: '任务审核',
						// 	menupic: null,
						// 	menuurl: '/grouprental/check'
						// }
					]
					},
					{
						menuname: "60岁人员巡防",
						menupic: "el-icon-s-promotion",
						menuurl: null,
						childrenmenu:[{
							menuname: '巡防任务',
							menupic: null,
							menuurl: '/sixtypatrol/task'
						},{
							menuname: '巡防问题',
							menupic: null,
							menuurl: '/sixtypatrol/problem'
						},{
							menuname: '周期修改',
							menupic: null,
							menuurl: '/sixtypatrol/cyclemodify'
						}]
					}],
					patrol: [{
						menuname: "自建房巡防",
						menupic: "el-icon-s-promotion",
						menuurl: null,
						childrenmenu:[{
							menuname: '巡防任务',
							menupic: null,
							menuurl: '/patrol/tasks'
						},{
							menuname: '巡防问题',
							menupic: null,
							menuurl: '/patrol/problems'
						},{
							menuname: '周期修改',
							menupic: null,
							menuurl: '/patrol/modify'
						}]
					},{
						menuname: "巡防记录",
						menupic: "el-icon-s-promotion",
						menuurl: null,
						childrenmenu:[{
							menuname: '巡防记录',
							menupic: null,
							menuurl: '/records'
						}]
					}],
					other: [{
							menuname: "小区管理",
							menupic: "el-icon-setting",
							menuurl: null,
							childrenmenu: [{
								menuname: "小区管理",
								menupic: null,
								menuurl: "/managexiaoqu/xiaoqu",
						
							},{
								menuname: "楼栋管理",
								menupic: null,
								menuurl: "/managexiaoqu/loudong",
						
							},{
								menuname: "房间管理",
								menupic: null,
								menuurl: "/managexiaoqu/fangjian",
						
							}],
						},
						{
							menuname: "网格管理",
							menupic: "el-icon-setting",
							menuurl: null,
							childrenmenu: [{
								menuname: "网格管理",
								menupic: null,
								menuurl: "/wangge/wg",
						
							},{
								menuname: "网格楼栋管理",
								menupic: null,
								menuurl: "/wangge/wgld",
						
							},{
								menuname: "人员管理",
								menupic: null,
								menuurl: "/wangge/wgy",
						
							},{
								menuname: "网格员管理",
								menupic: null,
								menuurl: "/wangge/wgwgy",
						
							},{
								menuname: "赋权管理",
								menupic: null,
								menuurl: "/wangge/fuquan",
						
							}],
						},
						{
							menuname: "标签管理",
							menupic: "el-icon-setting",
							menuurl: null,
							childrenmenu: [{
								menuname: "类型管理",
								menupic: null,
								menuurl: "/tag/management",
						
							},{
								menuname: "子标签管理",
								menupic: null,
								menuurl: "/tag/subtagmanage",
						
							}],
						},
						{
							menuname: "系统设置",
							menupic: "el-icon-setting",
							menuurl: null,
							childrenmenu: [{
								menuname: "账号管理",
								menupic: null,
								menuurl: "/system/account",

							}],
					}]
				}
			};
		},
		mounted() {

			//personAndHouse 人员/房屋综合信息 /shaicha/person
			//patrol 自建房巡防管理 /patrol/tasks
			//ninePlace 小场所 /ninesite/sitelist
			//other 数据维护 /managexiaoqu/xiaoqu
			let menuType = window.localStorage.getItem('menuType');
			this.menu_data = this.menu_datas[menuType];
		},
		methods: {
			//返回首页
			gotoHome(){
				this.$router.push({
					path: '/index'
				})
			},
			//修改密码
			editPassword() {
				this.form = {
					password: "",
					sure_password: "",
				};
				this.dialogFormVisible = true;
			},
			//取消修改
			cancelbtn() {
				this.dialogFormVisible = false;
				this.form = {
					password: "",
					sure_password: "",
				};
			},
			//确认修改密码
			surebtn_changePw() {
				if (this.form.password.trim() == "") {
					this.$message({
						message: "请输入新密码",
						type: "warning",
					});
					return false;
				}
				if (this.form.sure_password.trim() == "") {
					this.$message({
						message: "请输入确认新密码",
						type: "warning",
					});
					return false;
				}
				if (this.form.password.trim() != this.form.sure_password.trim()) {
					this.$message({
						message: "两次输入的密码不一致，请确认一致后再提交",
						type: "warning",
					});
					return false;
				}

				updatePwd({
					userid: this.GLOBAL.adminId(),
					password: this.form.password,
					passwordtwo: this.form.sure_password,
				}).then(res => {
					if (res.result == 200) {
						this.$message({
							message: "密码修改成功",
							type: "success",
						});
						this.cancelbtn();
					} else {
						this.$message({
							message: res.description,
							type: "warning",
						});
					}
				})
			},
			//退出系统
			quit() {
				let that = this;
				that
					.$confirm("确认要退出吗?", "退出确认", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						that.$message({
							message: "已成功退出",
							type: "success",
						});
						localStorage.clear();
						removeToken();
						that.$router.push({
							name: "login",
						});
					})
					.catch(() => {});
			},
			//获取菜单列表
			getList() {
				curUserMenu({
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						this.menu_data = res.detail.usermenu;
					} else {
						this.$message.warn(res.description)
					}
				})

			},
		},
	};
</script>

<style lang="scss">
	$headHeight: 60px;

	.addwidth {
		width: 230px !important;
		// span{
		//   display: initial;
		// }
	}

	.removewidth {
		width: 64px !important;
		// span{
		//   display: none  !important;
		// }
	}


	.router-tab {
		width: 100%;
		height: 100%;
	}

	.contain {
		height: 100%;
	}

	.header {
		line-height: $headHeight;
		height: $headHeight !important;
		position: relative;
		// background: url(../assets/imgs/headerbg.jpg) repeat;
		background: #081944;
		color: #fff;
		display: flex;
		align-items: center;
		// overflow: hidden;

		.logo {
			width: 34px;
			height: auto;
		}

		.tit {
			// width: 160px;
			height: auto;
			margin-left: 5px;
			display: inline-block;
			font-size: 22px;
			font-weight: 600;
			cursor: pointer;
		}

		.collapse {
			margin-left: 20px;
			cursor: pointer;
			opacity: 0.8;
			transition: 0.3s opacity;

			&:hover {
				opacity: 1;
			}

			i {
				font-size: 24px;
				color: #87b8e2;
			}
		}

		img {
			margin-top: -5px;
		}
	}

	.tips-box {
		margin-left: 100px;
		font-size: 16px;
		color: #E6A23C;
		width: 60%;
		height: 40px;
		overflow: hidden;

		.item {
			cursor: pointer;
		}
	}


	.header .quit {
		position: absolute;
		right: 40px;
		top: 0;
		cursor: pointer;
		transition: 0.3s color;
	}

	.header .quit i {
		color: #4389df;
		margin-right: 5px;
		font-size: 22px;
	}

	.header .quit:hover,
	.header .quit:hover i {
		color: #4c96f0;
	}

	.header .name {
		position: absolute;
		right: 110px;
		top: 0;
		text-align: center;
		color: #fff;
		font-size: 14px;
		line-height: $headHeight;

		.username {
			padding-left: 34px;
			position: relative;
			color: #a0baec;
			cursor: pointer;
		}

		.user {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 26px;
			height: 26px;
			line-height: 30px;
			text-align: center;
			border-radius: 50%;
			background: #e3f7ff;
			overflow: hidden;

			i {
				font-size: 20px;
				color: #4389df;
			}
		}
	}

	.header .menus {
		position: absolute;
		top: $headHeight;
		left: -5px;
		background: #fff;
		width: 180px;
		z-index: 1000;
		color: #000;
		padding: 10px 20px;
		box-sizing: border-box;
		box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
		display: none;
	}

	.header .menus.show {
		display: block;
	}

	.header .menus .password {
		height: 30px;
		line-height: 30px;
		cursor: pointer;

		i {
			margin-right: 10px;
			color: #2197ff;
			font-size: 15px;
		}
	}


	.contain .main {
		height: calc(100% - #{$headHeight});
	}

	.contain .side {
		height: 100%;
		background: #081944;
		transition: 0.2s width ease-in-out;

		.el-scrollbar__wrap {
			overflow-x: hidden !important;
		}

		.iconfont {
			margin-right: 6px;
			font-size: 18px;
			margin-left: 5px;
		}
	}

	.contain .side>ul {
		height: 100%;
	}

	.mainbox {
		padding: 0 !important;
	}

	.mainbox .router-tab-header {
		height: 46px !important;
	}

	.el-menu-item.is-active {
		color: #fff !important;
		background: #2c4d9d !important;
	}

	.notice-swipe {
		height: 40px;
		line-height: 40px;
	}
</style>